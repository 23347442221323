<template>
    <div class="content-main" :class="{ bg1: currentIndex == 0, bg2: currentIndex == 1 }">
        <router-link to="/service-case" class="toHome"></router-link>
        <div class="arrows">
            <img src="../assets/icon-arrow-left.png" alt="上一页" @click="bannerGoBack()" />
            <img src="../assets/icon-arrow-right.png" alt="下一页" @click="bannerGoNext()" />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    setup() {
        const currentIndex = ref(0);
        const bannerGoNext = () => {
            if (currentIndex.value >= 1) {
                currentIndex.value = 0;
            }
            else {
                currentIndex.value = currentIndex.value + 1;
            }

        }
        const bannerGoBack = () => {
            if (currentIndex.value == 0) {
                currentIndex.value = 1;
            }
            else {
                currentIndex.value = currentIndex.value - 1;
            }
        }
        return {
            currentIndex,
            bannerGoNext,
            bannerGoBack
        };
    },
}
</script>

<style scoped>
.content-main {
    position: relative;
    width: 100%;
    height: 630px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;
}
.toHome {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 60px;
    right: 80px;
    cursor: pointer;
}
.bg1 {
    background-image: url("../assets/anli/case3-01.jpg");
}

.bg2 {
    background-image: url("../assets/anli/case3-02.jpg");
}

.bg3 {
    background-image: url("../assets/anli/case3-03.jpg");
}

.arrows {
    position: absolute;
    bottom: 70px;
    right: 200px;
}
.arrows img {
    width: 40px;
    cursor: pointer;
}
.arrows img:first-child {
    margin-right: 60px;
}
</style>
